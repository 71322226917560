import React from 'react';
import Rostel from '../../images/partners/rostel.svg'
import Realiza from '../../images/partners/Realiza.png'
import ASGA from '../../images/partners/ASGA.png'
import CNG from '../../images/partners/CNG.png'
import MEZCAL from '../../images/partners/haciendalogo.png'
import FeatureCarousel from '../../components/FeatureCarousel'

import './style.css'

const Features2 = () => {

    return(
        <div className="wpo-section-style-2">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        {/* <span>We Provide the Best</span> */}
                        <h2>Aliados Estratégicos</h2>
                    </div>
                </div>
                <div className="row hide-carousel" style={{justifyContent:"center"}}>

                    <div className="col-lg-3 col-md-6 col-sm-12 col-d" style={{display:'flex', justifyContent:'center'}}>
                        <div className="wpo-section-icon" style={{'width': '100%'}}>
                            <img src={Rostel} alt="" style={{height:'100%', width:'100%'}}/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-d" style={{display:'flex', justifyContent:'center'}}>
                        <div className="wpo-section-icon">
                            <img src={CNG} style={{maxHeight:'100px'}} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-d" style={{display:'flex', justifyContent:'center'}}>
                        <div className="wpo-section-icon">
                            <img src={Realiza} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-d" style={{display:'flex', justifyContent:'center'}}>
                        <div className="wpo-section-icon">
                            <img src={ASGA} style={{maxHeight:'100px'}} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-d" style={{display:'flex', justifyContent:'center'}}>
                        <div className="wpo-section-icon">
                            <img src={MEZCAL} style={{height:'50%', width:'100%'}}/>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-sm-12 col-d">
                        <div className="wpo-section-item-2">
                            <div className="wpo-section-icon">
                              <img src={ASGA} alt=""/>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-lg-4 col-md-6 col-sm-12 col-d">
                        <div className="wpo-section-item-2">
                            <div className="wpo-section-icon">
                                <i className="fi flaticon-plane"></i>
                            </div>
                            <div className="wpo-section-content">
                                <p> <Link to="/freight">Air Freight</Link></p>
                                <span>There are many variations of passages of Lorem Ipsum</span>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="row show-carousel" style={{justifyContent:"center"}}>
                    <div className="col-sm-12">
                        <FeatureCarousel/>
                    </div>
                </div>
            </div>
        </div>
     )

}

export default Features2;
