import React, { Component } from 'react';
import emailjs from '@emailjs/browser';

class ContactForm2 extends Component {

    state = {
        name: '',
        // lname: '',
        email: '',
        number: '',
        company: '',
        // adress: '',
        notes: '',
        error: {}
    }


    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { name,
            // lname,
            email,
            number,
            company,
            // adress,
            notes, error } = this.state;

        if (name === '') {
            error.name = "Por favor ingresa tu nombre";
        }
        // if (lname === '') {
        //     error.lname = "Por favor ingresa tu apellido";
        // }
        if (email === '') {
            error.email = "Por favor ingresa tu correo";
        }
        if (number === '') {
            error.number = "Por favor ingresa tu número";
        }
        if (company === '') {
            error.company = "Por favor ingresa tu el nombre de tu empresa";
        }
        // if (adress === '') {
        //     error.adress = "Por favor ingresa tu dirección";
        // }
        if (notes === '') {
            error.notes = "Por favor ingresa tu nota";
        }


        if (error) {
            this.setState({
                error
            })
            console.log(error);
        }
        if (error.name === ''  && error.email === '' && error.company === '' && error.number === '' && error.notes === '') {
            // console.log('SUCCESS!')
            // this.setState({
            //     name: '',
            //     lname: '',
            //     email: '',
            //     number: '',
            //     company: '',
            //     adress: '',
            //     notes: '',
            //     error: {}
            // })

                // publicKey: 'b99ZfoULIWIbiYqMI',
            var templateParams = {
                name: name,
                // lname: lname,
                email: email,
                number: number,
                company: company,
                // adress: adress,
                notes: notes,
              };
            emailjs.send('service_6odqfjh', 'template_n5ggryx', templateParams, 'b99ZfoULIWIbiYqMI').then(
                function (response) {
                    alert('Gracias por contactarnos, en breve nos pondremos en contacto contigo.');
                  console.log('SUCCESS!', response.status, response.text);
                },
                function (err) {
                  console.log('FAILED...', err);
                },
              );
        }

    }

    render() {

        const { name,
            // lname,
            email,
            number,
            company,
            // adress,
            notes, error } = this.state;

        return (
            <form onSubmit={this.subimtHandler}>
                <div className="contact-form form-style row">
                    <div className="col-12 col-lg-6">
                        <input type="text" value={name} onChange={this.changeHandler} placeholder="Nombre*" id="fname" name="name" />
                        <p>{error.name ? error.name : ''}</p>
                    </div>
                    {/* <div className="col-12 col-lg-6">
                        <input type="text" value={lname} onChange={this.changeHandler} placeholder="Apellido" id="lname" name="lname" />
                        <p>{error.lname ? error.lname : ''}</p>
                    </div> */}
                    <div className="col-12  col-lg-6">
                        <input type="email" placeholder="Email*" onChange={this.changeHandler} value={email} id="email" name="email" />
                        <p>{error.email ? error.email : ''}</p>
                    </div>
                    <div className="col col-lg-6">
                        <input type="text" placeholder="Teléfono*" onChange={this.changeHandler} value={number} id="number" name="number" />
                        <p>{error.number ? error.number : ''}</p>
                    </div>
                    <div className="col-12  col-lg-6">
                        <input type="text" placeholder="Empresa*" onChange={this.changeHandler} value={company} id="company" name="company" />
                        <p>{error.company ? error.company : ''}</p>
                    </div>
                    {/* <div className="col-12  col-lg-6">
                        <input type="adress" placeholder="Dirección" onChange={this.changeHandler} value={adress} id="adress" name="adress" />
                        <p>{error.adress ? error.adress : ''}</p>
                    </div> */}
                    <div className="col-12 col-sm-12">
                        <textarea className="contact-textarea" value={notes} onChange={this.changeHandler} placeholder="Mensaje*" name="notes"></textarea>
                        <p>{error.notes ? error.notes : ''}</p>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="theme-btn">Enviar</button>
                    </div>
                </div>
            </form>
        )
    }
}

export default ContactForm2;
