import React from 'react';
// import abimg2 from '../../images/about/2.png'

import './style.css'

const AboutSection2 = () => {

    return(
        <div className="wpo-about-style-2">
        <div className="container">
            <div className="row">
                <div className="col-lg-6  offset-lg-6 about-wrap">
                    <div className="wpo-about-content">
                        <div className="wpo-about-icon">
                            <i className="fi flaticon-truck"></i>
                        </div>
                        <h2>¿Quiénes somos?</h2>
                            <p>Somos la empresa que te ofrece el mejor servicio de transporte terrestre de carga general y asesoría para el movimiento de mercancías.</p>
                            <span>Contáctanos y compártenos tu requerimiento</span>
                            <span>Te asesoraremos y ofreceremos el servicio que mejor se adapte a tu necesidad</span>
                            <span>Mantenemos una estrecha comunicación y coordinación contigo para brindarte un servicio de la más alta calidad</span>
                    </div>
                    {/* <div className="signature-section">
                        <div className="si-text">
                            <p>Ketty Raigott</p>
                            <span>Chairman & Chief Executive Officer</span>
                        </div>
                        <img src={abimg2} alt=""/>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
     )

}

export default AboutSection2;
