import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Gdl_Mty from '../../images/routes/Gdl-Mty.png';
import Gdl_Manzanillo from '../../images/routes/Gdl-Manzanillo.png';
import Gdl_Bajio from '../../images/routes/Gdl-Bajio.png';
import Gdl from '../../images/routes/Gdl.png';

class Routes extends Component {
    render() {
        return (
            <div className="wpo-team-area-2">
                <div className="container">
                    <div className="col-l2">
                        <div className="wpo-section-title text-center">
                            {/* <span>Estamos contigo</span> */}
                            <h2>Rutas Principales</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={Gdl_Mty} alt=""/>
                                </div>
                                <div className="wpo-team-content">
                                    <h4>Guadalajara - Monterrery</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={Gdl_Manzanillo} alt=""/>
                                </div>
                                <div className="wpo-team-content">
                                    <h4>Guadalajara - Manzanillo</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={Gdl_Bajio} alt=""/>
                                </div>
                                <div className="wpo-team-content">
                                    <h4>Guadalajara - Bajío</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={Gdl} alt=""/>
                                </div>
                                <div className="wpo-team-content">
                                    <h4>Guadalajara Local</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Routes;
