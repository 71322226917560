import React, { Component } from 'react';
import Slider from "react-slick";
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Rostel from '../../images/partners/rostel.svg'
import Realiza from '../../images/partners/Realiza.png'
import ASGA from '../../images/partners/ASGA.png'
import CNG from '../../images/partners/CNG.png'
import MEZCAL from '../../images/partners/haciendalogo.png'

class Testimonial extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 1200,
            slidesToShow: 1,
            arrows: false,
            margin:50,
            autoplay:true,
            slidesToScroll: 1,
            centerPadding: 30,
            focusOnSelect: false,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };
        return (
            // <div className="wpo-testimonial-area">
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="wpo-testimonial-active owl-carousel">
                                <Slider {...settings}>
                                    <div className="wpo-testimonial-wrap">
                                        <div className="wpo-testimonial-item" style={{height:'400px', alignContent:'center'}}>
                                            <div className="wpo-testimonial-content">
                                            <img src={Rostel} alt="" style={{height:'100%', width:'100%'}}/>
                                            </div>
                                        </div>
                                        <div className="test-c d-none d-lg-block"></div>
                                        <div className="test-b d-none d-lg-block"></div>
                                    </div>
                                    <div className="wpo-testimonial-wrap">
                                        <div className="wpo-testimonial-item" style={{height:'400px', alignContent:'center'}}>
                                            <div className="wpo-testimonial-content">
                                            <img src={CNG} style={{height:'100%', width:'100%'}} alt=""/>
                                            </div>
                                        </div>
                                        <div className="test-c d-none d-lg-block"></div>
                                        <div className="test-b d-none d-lg-block"></div>
                                    </div>
                                    <div className="wpo-testimonial-wrap">
                                        <div className="wpo-testimonial-item" style={{height:'400px', alignContent:'center'}}>
                                            <div className="wpo-testimonial-content">
                                            <img src={Realiza} style={{height:'100%', width:'100%'}} alt=""/>
                                            </div>
                                        </div>
                                        <div className="test-c d-none d-lg-block"></div>
                                        <div className="test-b d-none d-lg-block"></div>
                                    </div>
                                    <div className="wpo-testimonial-wrap">
                                        <div className="wpo-testimonial-item" style={{height:'400px', alignContent:'center'}}>
                                            <div className="wpo-testimonial-content">
                                            <img src={ASGA} style={{height:'100%', width:'100%'}} alt=""/>
                                            </div>
                                        </div>
                                        <div className="test-c d-none d-lg-block"></div>
                                        <div className="test-b d-none d-lg-block"></div>
                                    </div>
                                    <div className="wpo-testimonial-wrap">
                                        <div className="wpo-testimonial-item" style={{height:'400px', alignContent:'center'}}>
                                            <div className="wpo-testimonial-content">
                                            <img src={MEZCAL} style={{height:'100%', width:'100%'}} alt=""/>
                                            </div>
                                        </div>
                                        <div className="test-c d-none d-lg-block"></div>
                                        <div className="test-b d-none d-lg-block"></div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Testimonial;
