import React from 'react';
import {Link} from 'react-router-dom'
import truck from '../../images/service/truck.jpg'
import driver from '../../images/service/driver.jpg'
import service from '../../images/service/service.jpg'
import gps from '../../images/service/GPS.jpg'
import sprite from '../../fonts/MyCollection.svg'
import './style.css'


const ServiceArea2 = ( props ) => {

    return(
        <div className="wpo-service-area">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        <span>Proporcionamos lo mejor</span>
                        <h2>Nuestros Servicios</h2>
                    </div>
                </div>
                <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={truck} alt=""/>
                                </div>
                                <div className="service-content">
                                    <div className="wpo-section-icon section2">
                                        <svg width="120px" height="120px" className="fi icon">
                                            <use xlinkHref={`${sprite}#trailer`} />
                                        </svg>
                                    </div>
                                    <h3>Caja Seca - 53'</h3>
                                    <p>Nos especializamos en cargas que requieren unidades de caja seca de 53'</p>
                                    {/* <Link to="/servicesingle">See More...</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={gps} alt=""/>
                                </div>
                                <div className="service-content">
                                    <div className="wpo-section-icon section2">
                                        <svg width="120px" height="120px" className="fi icon">
                                            <use xlinkHref={`${sprite}#location`} />
                                        </svg>
                                    </div>
                                    <h3>Monitoreo 24/7</h3>
                                    <p>Proporcionamos monitoreo GPS 24/7 para que se sienta seguro de su entrega</p>
                                    {/* <Link to="/servicesingle">See More...</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={driver} alt=""/>
                                </div>
                                <div className="service-content2">
                                    <div className="wpo-section-icon section2">
                                        <svg width="120px" height="120px" className="fi icon">
                                            <use xlinkHref={`${sprite}#driver`} />
                                        </svg>
                                    </div>
                                    <h3>Operadores</h3>
                                    <p>Nuestros operadores cuentan con más de 20 años de experiencia en la industria</p>
                                    {/* <Link to="/servicesingle">See More...</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="service-img">
                                    <img src={service} alt=""/>
                                </div>
                                <div className="service-content3">
                                    <div className="wpo-section-icon section2">
                                        <svg width="120px" height="120px" className="fi icon">
                                            <use xlinkHref={`${sprite}#support`} />
                                        </svg>
                                    </div>
                                    <h3>Communicación</h3>
                                    <p>Contamos con canales de comunicacion para manterlo informado de su envío</p>
                                    {/* <Link to="/servicesingle">See More...</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )

}

export default ServiceArea2;
