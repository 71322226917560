import React from 'react';

import './style.css'

const ServiceArea = ( props ) => {

    return(
        <div className="wpo-service-s2">
            <div className="container">
                <div className="col-l2">
                    <div className="wpo-section-title text-center">
                        {/* <span>We Provide the Best</span> */}
                        <h2>¿Por qué Kinexion?</h2>
                    </div>
                </div>
                <div className="row" style={{justifyContent:"center"}}>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fa fa-assistive-listening-systems fa-2x"></i>
                                </div>
                                <h2>Empatía</h2>
                                <p>Entendemos tus necesidades y forjamos una relación profunda</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fa fa-thumbs-o-up fa-2x"></i>
                                </div>
                                <h2>Confianza</h2>
                                <p>Construimos juntos un fundamento que promueve la comunicación abierta</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fa fa-heartbeat fa-2x"></i>
                                </div>
                                <h2>Pasión</h2>
                                <p>Siempre buscamos la innovación, desafiando constantemente los límites</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fa fa-handshake-o fa-2x"></i>
                                </div>
                                <h2>Compromiso</h2>
                                <p>Nuestro compromiso a la excelencia asegura una gran experiencia</p>
                            </div>
                        </div>
                    </div><div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fa fa-arrows-alt fa-2x"></i>
                                </div>
                                <h2>Flexibilidad</h2>
                                <p>Nos adaptamos a tus necesidades y redefinimos límites</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="fi flaticon-construction"></i>
                                </div>
                                <h2>Logistic Solutions</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, obcaecati.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="flaticon-truck-2"></i>
                                </div>
                                <h2>Packaging and Store</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, obcaecati.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="wpo-service-item">
                            <div className="wpo-service-single">
                                <div className="wpo-service-icon">
                                    <i className="flaticon-trolley"></i>
                                </div>
                                <h2>Warehousing</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, obcaecati.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
     )

}

export default ServiceArea;
