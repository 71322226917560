import React from 'react';
import ContactForm2 from '../CommentForm2'

import './style.css'

const Contactpage = () => {

    return(
        <div className="contact-page-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="contact-page-item">
                            <h2>Nuestro Contacto</h2>
                            <div className="whatsapp">
                                <h3>WhatsApp</h3>
                                <span>+52 33 2922 6745</span>
                            </div>
                            <div className="number">
                                <h3>Número</h3>
                                <span>+52 33 1294 8427</span>
                            </div>
                            {/* <div className="phone">
                                <h3>Phone</h3>
                                <span>0-123-456-7890</span>
                                <span>0-123-456-7890</span>
                            </div> */}
                            <div className="email">
                                <h3>Email</h3>
                                <span>contacto@kinexion.mx</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="contact-area contact-area-2 contact-area-3">
                            <h2>Compártenos tu necesidad</h2>
                            <ContactForm2/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="contact-map">
                            <iframe title='db' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119452.63937119555!2d-103.41779967571529!3d20.67385117751159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b18cb52fd39b%3A0xd63d9302bf865750!2sGuadalajara%2C%20Jalisco%2C%20Mexico!5e0!3m2!1sen!2sus!4v1711355935960!5m2!1sen!2sus"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )

}

export default Contactpage;
